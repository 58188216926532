export const HighlightMintManager = [
  { type: 'error', inputs: [], name: 'AllowlistInvalid' },
  { type: 'error', inputs: [], name: 'AlreadyRegisteredWithId' },
  { type: 'error', inputs: [], name: 'CurrencyTypeInvalid' },
  { type: 'error', inputs: [], name: 'EtherSendFailed' },
  { type: 'error', inputs: [], name: 'InvalidClaim' },
  { type: 'error', inputs: [], name: 'InvalidExecutorChanged' },
  { type: 'error', inputs: [], name: 'InvalidMechanic' },
  { type: 'error', inputs: [], name: 'InvalidPaymentAmount' },
  { type: 'error', inputs: [], name: 'InvalidTotalClaimed' },
  { type: 'error', inputs: [], name: 'MechanicPaused' },
  { type: 'error', inputs: [], name: 'MintFeeTooLow' },
  { type: 'error', inputs: [], name: 'MintPaused' },
  { type: 'error', inputs: [], name: 'OnchainVectorMintGuardFailed' },
  { type: 'error', inputs: [], name: 'SenderNotClaimer' },
  { type: 'error', inputs: [], name: 'SenderNotDirectEOA' },
  { type: 'error', inputs: [], name: 'Unauthorized' },
  { type: 'error', inputs: [], name: 'UnsafeMintRecipient' },
  { type: 'error', inputs: [], name: 'VectorUpdateActionFrozen' },
  { type: 'error', inputs: [], name: 'VectorWrongCollectionType' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'AdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'beacon',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'BeaconUpgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'vectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'contractAddress',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'onChainVector',
        internalType: 'bool',
        type: 'bool',
        indexed: true,
      },
      {
        name: 'tokenIds',
        internalType: 'uint256[]',
        type: 'uint256[]',
        indexed: false,
      },
    ],
    name: 'ChooseTokenMint',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contractAddress',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'tokenIds',
        internalType: 'uint256[]',
        type: 'uint256[]',
        indexed: false,
      },
    ],
    name: 'CreatorReservesChooseMint',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contractAddress',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'isEditionBased',
        internalType: 'bool',
        type: 'bool',
        indexed: true,
      },
      {
        name: 'editionId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'numMinted',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'CreatorReservesNumMint',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'currency',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'paymentRecipient',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'vectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'payer',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'amountToCreator',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'percentageBPSOfTotal',
        internalType: 'uint32',
        type: 'uint32',
        indexed: false,
      },
    ],
    name: 'ERC20Payment',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'vectorId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'editionId',
        internalType: 'uint48',
        type: 'uint48',
        indexed: true,
      },
      {
        name: 'contractAddress',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'EditionVectorCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'version', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'mechanicVectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      { name: 'paused', internalType: 'bool', type: 'bool', indexed: true },
    ],
    name: 'MechanicVectorPauseSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'mechanicVectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'mechanic',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'contractAddress',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'editionId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'isEditionBased',
        internalType: 'bool',
        type: 'bool',
        indexed: false,
      },
    ],
    name: 'MechanicVectorRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'paymentRecipient',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'vectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'amountToCreator',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'percentageBPSOfTotal',
        internalType: 'uint32',
        type: 'uint32',
        indexed: false,
      },
    ],
    name: 'NativeGasTokenPayment',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'vectorId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'contractAddress',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'onChainVector',
        internalType: 'bool',
        type: 'bool',
        indexed: true,
      },
      {
        name: 'numMinted',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'NumTokenMint',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'executor',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      { name: 'added', internalType: 'bool', type: 'bool', indexed: true },
    ],
    name: 'PlatformExecutorChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'vectorId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'contractAddress',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'SeriesVectorCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'vectorId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'VectorDeleted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'vectorId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      { name: 'paused', internalType: 'bool', type: 'bool', indexed: true },
      {
        name: 'flexibleData',
        internalType: 'uint128',
        type: 'uint128',
        indexed: true,
      },
    ],
    name: 'VectorMetadataSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'vectorId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'VectorUpdated',
  },
  {
    type: 'function',
    inputs: [{ name: '_executor', internalType: 'address', type: 'address' }],
    name: 'addOrDeprecatePlatformExecutor',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      {
        name: '_vector',
        internalType: 'struct IAbridgedMintVector.AbridgedVectorData',
        type: 'tuple',
        components: [
          { name: 'contractAddress', internalType: 'uint160', type: 'uint160' },
          { name: 'startTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'endTimestamp', internalType: 'uint48', type: 'uint48' },
          {
            name: 'paymentRecipient',
            internalType: 'uint160',
            type: 'uint160',
          },
          {
            name: 'maxTotalClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          {
            name: 'totalClaimedViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'currency', internalType: 'uint160', type: 'uint160' },
          { name: 'tokenLimitPerTx', internalType: 'uint48', type: 'uint48' },
          {
            name: 'maxUserClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'pricePerToken', internalType: 'uint192', type: 'uint192' },
          { name: 'editionId', internalType: 'uint48', type: 'uint48' },
          {
            name: 'editionBasedCollection',
            internalType: 'bool',
            type: 'bool',
          },
          { name: 'requireDirectEOA', internalType: 'bool', type: 'bool' },
          { name: 'allowlistRoot', internalType: 'bytes32', type: 'bytes32' },
        ],
      },
    ],
    name: 'createAbridgedVector',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'collection', internalType: 'address', type: 'address' },
      { name: 'isEditionBased', internalType: 'bool', type: 'bool' },
      { name: 'editionId', internalType: 'uint256', type: 'uint256' },
      { name: 'numToMint', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenIds', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'isCollectorsChoice', internalType: 'bool', type: 'bool' },
      { name: 'recipient', internalType: 'address', type: 'address' },
    ],
    name: 'creatorReservesMint',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [{ name: 'vectorId', internalType: 'uint256', type: 'uint256' }],
    name: 'deleteAbridgedVector',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      {
        name: '_claim',
        internalType: 'struct MintManager.Claim',
        type: 'tuple',
        components: [
          { name: 'currency', internalType: 'address', type: 'address' },
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'claimer', internalType: 'address', type: 'address' },
          {
            name: 'paymentRecipient',
            internalType: 'address payable',
            type: 'address',
          },
          { name: 'pricePerToken', internalType: 'uint256', type: 'uint256' },
          { name: 'numTokensToMint', internalType: 'uint64', type: 'uint64' },
          {
            name: 'maxClaimableViaVector',
            internalType: 'uint256',
            type: 'uint256',
          },
          {
            name: 'maxClaimablePerUser',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'editionId', internalType: 'uint256', type: 'uint256' },
          {
            name: 'claimExpiryTimestamp',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'claimNonce', internalType: 'bytes32', type: 'bytes32' },
          {
            name: 'offchainVectorId',
            internalType: 'bytes32',
            type: 'bytes32',
          },
        ],
      },
      { name: '_signature', internalType: 'bytes', type: 'bytes' },
      { name: '_recipient', internalType: 'address', type: 'address' },
    ],
    name: 'gatedMintEdition721',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [
      {
        name: 'claim',
        internalType: 'struct MintManager.Claim',
        type: 'tuple',
        components: [
          { name: 'currency', internalType: 'address', type: 'address' },
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'claimer', internalType: 'address', type: 'address' },
          {
            name: 'paymentRecipient',
            internalType: 'address payable',
            type: 'address',
          },
          { name: 'pricePerToken', internalType: 'uint256', type: 'uint256' },
          { name: 'numTokensToMint', internalType: 'uint64', type: 'uint64' },
          {
            name: 'maxClaimableViaVector',
            internalType: 'uint256',
            type: 'uint256',
          },
          {
            name: 'maxClaimablePerUser',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'editionId', internalType: 'uint256', type: 'uint256' },
          {
            name: 'claimExpiryTimestamp',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'claimNonce', internalType: 'bytes32', type: 'bytes32' },
          {
            name: 'offchainVectorId',
            internalType: 'bytes32',
            type: 'bytes32',
          },
        ],
      },
      { name: 'claimSignature', internalType: 'bytes', type: 'bytes' },
      { name: 'mintRecipient', internalType: 'address', type: 'address' },
    ],
    name: 'gatedSeriesMint',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [
      {
        name: 'claim',
        internalType: 'struct MintManager.SeriesClaim',
        type: 'tuple',
        components: [
          { name: 'currency', internalType: 'address', type: 'address' },
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'claimer', internalType: 'address', type: 'address' },
          {
            name: 'paymentRecipient',
            internalType: 'address payable',
            type: 'address',
          },
          { name: 'pricePerToken', internalType: 'uint256', type: 'uint256' },
          { name: 'maxPerTxn', internalType: 'uint64', type: 'uint64' },
          {
            name: 'maxClaimableViaVector',
            internalType: 'uint64',
            type: 'uint64',
          },
          {
            name: 'maxClaimablePerUser',
            internalType: 'uint64',
            type: 'uint64',
          },
          {
            name: 'claimExpiryTimestamp',
            internalType: 'uint64',
            type: 'uint64',
          },
          { name: 'claimNonce', internalType: 'bytes32', type: 'bytes32' },
          {
            name: 'offchainVectorId',
            internalType: 'bytes32',
            type: 'bytes32',
          },
        ],
      },
      { name: 'claimSignature', internalType: 'bytes', type: 'bytes' },
      { name: 'mintRecipient', internalType: 'address', type: 'address' },
      { name: 'tokenIds', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'gatedSeriesMintChooseToken',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [{ name: 'vectorId', internalType: 'uint256', type: 'uint256' }],
    name: 'getAbridgedVector',
    outputs: [
      {
        name: '',
        internalType: 'struct IAbridgedMintVector.AbridgedVector',
        type: 'tuple',
        components: [
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'startTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'endTimestamp', internalType: 'uint48', type: 'uint48' },
          {
            name: 'paymentRecipient',
            internalType: 'address',
            type: 'address',
          },
          {
            name: 'maxTotalClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          {
            name: 'totalClaimedViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'currency', internalType: 'address', type: 'address' },
          { name: 'tokenLimitPerTx', internalType: 'uint48', type: 'uint48' },
          {
            name: 'maxUserClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'pricePerToken', internalType: 'uint192', type: 'uint192' },
          { name: 'editionId', internalType: 'uint48', type: 'uint48' },
          {
            name: 'editionBasedCollection',
            internalType: 'bool',
            type: 'bool',
          },
          { name: 'requireDirectEOA', internalType: 'bool', type: 'bool' },
          { name: 'allowlistRoot', internalType: 'bytes32', type: 'bytes32' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'vectorId', internalType: 'uint256', type: 'uint256' }],
    name: 'getAbridgedVectorMetadata',
    outputs: [
      { name: '', internalType: 'bool', type: 'bool' },
      { name: '', internalType: 'uint128', type: 'uint128' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'vectorId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getClaimNoncesUsedForOffchainVector',
    outputs: [{ name: '', internalType: 'bytes32[]', type: 'bytes32[]' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'vectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'user', internalType: 'address', type: 'address' },
    ],
    name: 'getNumClaimedPerUserOffchainVector',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'platform', internalType: 'address payable', type: 'address' },
      { name: '_owner', internalType: 'address', type: 'address' },
      { name: 'trustedForwarder', internalType: 'address', type: 'address' },
      { name: 'initialExecutor', internalType: 'address', type: 'address' },
      {
        name: 'initialPlatformMintFee',
        internalType: 'uint256',
        type: 'uint256',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'vectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'nonce', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'isNonceUsed',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '_executor', internalType: 'address', type: 'address' }],
    name: 'isPlatformExecutor',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'forwarder', internalType: 'address', type: 'address' }],
    name: 'isTrustedForwarder',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'tokenIds', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'mechanicMintChoose',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'numToMint', internalType: 'uint32', type: 'uint32' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'mechanicMintNum',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    name: 'mechanicVectorMetadata',
    outputs: [
      { name: 'contractAddress', internalType: 'address', type: 'address' },
      { name: 'editionId', internalType: 'uint96', type: 'uint96' },
      { name: 'mechanic', internalType: 'address', type: 'address' },
      { name: 'isEditionBased', internalType: 'bool', type: 'bool' },
      { name: 'isChoose', internalType: 'bool', type: 'bool' },
      { name: 'paused', internalType: 'bool', type: 'bool' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    name: 'offchainVectorsClaimState',
    outputs: [{ name: 'numClaimed', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      {
        name: '_mechanicVectorMetadata',
        internalType: 'struct IMechanicData.MechanicVectorMetadata',
        type: 'tuple',
        components: [
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'editionId', internalType: 'uint96', type: 'uint96' },
          { name: 'mechanic', internalType: 'address', type: 'address' },
          { name: 'isEditionBased', internalType: 'bool', type: 'bool' },
          { name: 'isChoose', internalType: 'bool', type: 'bool' },
          { name: 'paused', internalType: 'bool', type: 'bool' },
        ],
      },
      { name: 'seed', internalType: 'uint96', type: 'uint96' },
      { name: 'vectorData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'registerMechanicVector',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'vectorId', internalType: 'uint256', type: 'uint256' },
      { name: 'pause', internalType: 'bool', type: 'bool' },
      { name: 'flexibleData', internalType: 'uint128', type: 'uint128' },
    ],
    name: 'setAbridgedVectorMetadata',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'mechanicVectorId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'pause', internalType: 'bool', type: 'bool' },
    ],
    name: 'setPauseOnMechanicMintVector',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'vectorId', internalType: 'uint256', type: 'uint256' },
      {
        name: '_newVector',
        internalType: 'struct IAbridgedMintVector.AbridgedVector',
        type: 'tuple',
        components: [
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'startTimestamp', internalType: 'uint48', type: 'uint48' },
          { name: 'endTimestamp', internalType: 'uint48', type: 'uint48' },
          {
            name: 'paymentRecipient',
            internalType: 'address',
            type: 'address',
          },
          {
            name: 'maxTotalClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          {
            name: 'totalClaimedViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'currency', internalType: 'address', type: 'address' },
          { name: 'tokenLimitPerTx', internalType: 'uint48', type: 'uint48' },
          {
            name: 'maxUserClaimableViaVector',
            internalType: 'uint48',
            type: 'uint48',
          },
          { name: 'pricePerToken', internalType: 'uint192', type: 'uint192' },
          { name: 'editionId', internalType: 'uint48', type: 'uint48' },
          {
            name: 'editionBasedCollection',
            internalType: 'bool',
            type: 'bool',
          },
          { name: 'requireDirectEOA', internalType: 'bool', type: 'bool' },
          { name: 'allowlistRoot', internalType: 'bytes32', type: 'bytes32' },
        ],
      },
      {
        name: 'updateConfig',
        internalType: 'struct IAbridgedMintVector.UpdateAbridgedVectorConfig',
        type: 'tuple',
        components: [
          {
            name: 'updateStartTimestamp',
            internalType: 'uint16',
            type: 'uint16',
          },
          {
            name: 'updateEndTimestamp',
            internalType: 'uint16',
            type: 'uint16',
          },
          {
            name: 'updatePaymentRecipient',
            internalType: 'uint16',
            type: 'uint16',
          },
          {
            name: 'updateMaxTotalClaimableViaVector',
            internalType: 'uint16',
            type: 'uint16',
          },
          {
            name: 'updateTokenLimitPerTx',
            internalType: 'uint16',
            type: 'uint16',
          },
          {
            name: 'updateMaxUserClaimableViaVector',
            internalType: 'uint16',
            type: 'uint16',
          },
          { name: 'updatePricePerToken', internalType: 'uint8', type: 'uint8' },
          { name: 'updateAllowlistRoot', internalType: 'uint8', type: 'uint8' },
          {
            name: 'updateRequireDirectEOA',
            internalType: 'uint8',
            type: 'uint8',
          },
          { name: 'updateMetadata', internalType: 'uint8', type: 'uint8' },
        ],
      },
      { name: 'pause', internalType: 'bool', type: 'bool' },
      { name: 'flexibleData', internalType: 'uint128', type: 'uint128' },
    ],
    name: 'updateAbridgedVector',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'newPlatform', internalType: 'address payable', type: 'address' },
      { name: 'newPlatformMintFee', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'updatePlatformAndMintFee',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
    ],
    name: 'upgradeTo',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'address', type: 'address' },
    ],
    name: 'userClaims',
    outputs: [{ name: '', internalType: 'uint64', type: 'uint64' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'vectorId', internalType: 'uint256', type: 'uint256' },
      { name: 'numTokensToMint', internalType: 'uint48', type: 'uint48' },
      { name: 'mintRecipient', internalType: 'address', type: 'address' },
    ],
    name: 'vectorMint721',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'vectorMutabilities',
    outputs: [
      { name: 'updatesFrozen', internalType: 'uint8', type: 'uint8' },
      { name: 'deleteFrozen', internalType: 'uint8', type: 'uint8' },
      { name: 'pausesFrozen', internalType: 'uint8', type: 'uint8' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'vectorToEditionId',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'vectors',
    outputs: [
      { name: 'contractAddress', internalType: 'address', type: 'address' },
      { name: 'currency', internalType: 'address', type: 'address' },
      {
        name: 'paymentRecipient',
        internalType: 'address payable',
        type: 'address',
      },
      { name: 'startTimestamp', internalType: 'uint256', type: 'uint256' },
      { name: 'endTimestamp', internalType: 'uint256', type: 'uint256' },
      { name: 'pricePerToken', internalType: 'uint256', type: 'uint256' },
      { name: 'tokenLimitPerTx', internalType: 'uint64', type: 'uint64' },
      {
        name: 'maxTotalClaimableViaVector',
        internalType: 'uint64',
        type: 'uint64',
      },
      {
        name: 'maxUserClaimableViaVector',
        internalType: 'uint64',
        type: 'uint64',
      },
      { name: 'totalClaimedViaVector', internalType: 'uint64', type: 'uint64' },
      { name: 'allowlistRoot', internalType: 'bytes32', type: 'bytes32' },
      { name: 'paused', internalType: 'uint8', type: 'uint8' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      {
        name: 'claim',
        internalType: 'struct MintManager.Claim',
        type: 'tuple',
        components: [
          { name: 'currency', internalType: 'address', type: 'address' },
          { name: 'contractAddress', internalType: 'address', type: 'address' },
          { name: 'claimer', internalType: 'address', type: 'address' },
          {
            name: 'paymentRecipient',
            internalType: 'address payable',
            type: 'address',
          },
          { name: 'pricePerToken', internalType: 'uint256', type: 'uint256' },
          { name: 'numTokensToMint', internalType: 'uint64', type: 'uint64' },
          {
            name: 'maxClaimableViaVector',
            internalType: 'uint256',
            type: 'uint256',
          },
          {
            name: 'maxClaimablePerUser',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'editionId', internalType: 'uint256', type: 'uint256' },
          {
            name: 'claimExpiryTimestamp',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'claimNonce', internalType: 'bytes32', type: 'bytes32' },
          {
            name: 'offchainVectorId',
            internalType: 'bytes32',
            type: 'bytes32',
          },
        ],
      },
      { name: 'signature', internalType: 'bytes', type: 'bytes' },
      { name: 'expectedMsgSender', internalType: 'address', type: 'address' },
    ],
    name: 'verifyClaim',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'amountToWithdraw', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawNativeGasToken',
    outputs: [],
    stateMutability: 'nonpayable',
  },
] as const;
